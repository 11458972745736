export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const APP_SET_LOADED = 'APP_SET_LOADED';

export const FRAGRANCES_CREATE_REQUEST = 'FRAGRANCES_CREATE_REQUEST';
export const FRAGRANCES_FETCH_ALL_FAIL = 'FRAGRANCES_FETCH_ALL_FAIL';
export const FRAGRANCES_FETCH_ALL_REQUEST = 'FRAGRANCES_FETCH_ALL_REQUEST';
export const FRAGRANCES_FETCH_ALL_SUCCESS = 'FRAGRANCES_FETCH_ALL_SUCCESS';

export const INGREDIENTS_FETCH_ALL_FAIL = 'INGREDIENTS_FETCH_ALL_FAIL';
export const INGREDIENTS_FETCH_ALL_REQUEST = 'INGREDIENTS_FETCH_ALL_REQUEST';
export const INGREDIENTS_FETCH_ALL_SUCCESS = 'INGREDIENTS_FETCH_ALL_SUCCESS';

export const ORDER_FETCH_REQUEST = 'ORDER_FETCH_ALL_REQUEST';
export const ORDER_FETCH_SUCCESS = 'ORDER_FETCH_ALL_SUCCESS';
export const ORDER_FETCH_FAIL = 'ORDER_FETCH_ALL_FAIL';

export const MACHINE_STATE_WAITING_FOR_CONFIG = 'WAITING FOR CONFIG';
export const MACHINE_STATE_CALIBRATING = 'CALIBRATING';
export const MACHINE_STATE_IN_PRODUCTION = 'OK';
export const MACHINE_STATE_CRITIAL = 'CRITIAL';
export const MACHINE_STATE_OFF = 'OFF';
export const MACHINE_STATE_ERRORS = 'ERRORS';
export const MACHINE_STATE_UNKNOWN = 'UNKNOWN';

export const $$_MACHINE_ACTION = '$$_MACHINE_ACTION';

export const MACHINE = {
  FLOW: {
    STATE: {
      UPDATE: 'machine.flow.state.update',
    },
  },
};
