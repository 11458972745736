import {
  FRAGRANCES_FETCH_ALL_REQUEST,
  FRAGRANCES_FETCH_ALL_SUCCESS,
} from '../constants';

const initialState = {
  loaded: false,
  isFetching: false,
  fragrance: {},
  selected: [],
  error: {
    loading: false,
    logs: [],
  },
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case FRAGRANCES_FETCH_ALL_REQUEST:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case FRAGRANCES_FETCH_ALL_SUCCESS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        fragrance: action.payload,
      };
    default:
      return state;
  }
}
