import _ from 'lodash';

const initialState = {
  machineState: 0,
  flowState: 0,
  generalStatus: {
    name: 'UNKNOWN',
    state: 'unknown',
  },
  assistanceRequested: null,
};

export const STATUS_ENUMS = {
  MACHINE_STATES: {
    WAITING_FOR_CONFIG: 1,
    IN_PRODUCTION: 2,
    CRITICAL: 3,
    HEADING_TO_CRITICAL: 4,
    CONFIGURING: 6,
    RESET_DEVICES: 7,
    HEADING_TO_SOFT_POWER: 8,
    SOFT_POWER: 9,
  },
  FLOW_STATES: {
    IDLE: 0,
    CALIBRATING: 1,
    PRODUCING: 2,
    CRITICAL: 3,
    RESOLVING: 4,
  },
  GENERAL: {
    INIT: 'init',
    ON: 'on',
    WARNING: 'warning',
    ERROR: 'error',
    OFF: 'off',
  },
};

const generalStatus = (machineState, flowState) => {
  const { MACHINE_STATES, FLOW_STATES, GENERAL } = STATUS_ENUMS;

  const machineName = _.invert(MACHINE_STATES)[machineState];
  const flowName = _.invert(FLOW_STATES)[flowState];

  let name = flowName;
  let state = GENERAL.ON;

  if (
    machineState === MACHINE_STATES.WAITING_FOR_CONFIG ||
    machineState === MACHINE_STATES.CONFIGURING ||
    machineState === MACHINE_STATES.RESET_DEVICES
  ) {
    name = machineName;
    state = GENERAL.INIT;
  }

  if (flowState === FLOW_STATES.CALIBRATING) {
    name = flowName;
    state = GENERAL.INIT;
  }

  if (flowState === FLOW_STATES.RESOLVING) {
    name = flowName;
    state = GENERAL.WARNING;
  }

  if (flowState === FLOW_STATES.CRITICAL) {
    name = flowName;
    state = GENERAL.ERROR;
  }

  if (
    machineState === MACHINE_STATES.SOFT_POWER ||
    machineState === MACHINE_STATES.HEADING_TO_SOFT_POWER
  ) {
    name = machineName;
    state = GENERAL.OFF;
  }

  if (flowState === FLOW_STATES.IDLE && _.isNil(machineState)) {
    name = flowName;
    state = GENERAL.OFF;
  }

  if (machineState === MACHINE_STATES.CRITICAL) {
    name = machineName;
    state = GENERAL.ERROR;
  }

  if (
    flowState === FLOW_STATES.PRODUCING &&
    machineState === MACHINE_STATES.IN_PRODUCTION
  ) {
    name = 'OK';
    state = GENERAL.ON;
  }

  return { name, state };
};

export default function status(state = initialState, { type, payload }) {
  const { GENERAL } = STATUS_ENUMS;

  switch (type) {
    case 'kurt.state.update.machine': {
      const { machineState } = payload;

      const generalStatusVal = generalStatus(machineState, state.flowState);

      return {
        ...state,
        machineState,
        generalStatus: generalStatusVal,
        // When the state changes to anything else reset the assistanceRequested
        ...([GENERAL.ON, GENERAL.OFF, GENERAL.INIT].includes(
          generalStatusVal.state,
        ) && {
          assistanceRequested: null,
        }),
      };
    }
    case 'machine.flow.state.update': {
      const flowState = payload;

      if (!flowState) return state;

      const generalStatusVal = generalStatus(state.machineState, flowState);

      return {
        ...state,
        flowState,
        generalStatus: generalStatusVal,
        // When the state changes to anything else reset the assistanceRequested
        ...([GENERAL.ON, GENERAL.OFF, GENERAL.INIT].includes(
          generalStatusVal.state,
        ) && {
          assistanceRequested: null,
        }),
      };
    }
    case 'resolve.assist-requested': {
      const { messages } = payload;

      return {
        ...state,
        assistanceRequested: messages,
      };
    }
    case 'websocket-io.actions.resolve.assist-completed': {
      return {
        ...state,
        assistanceRequested: null,
      };
    }
    default: {
      return state;
    }
  }
}
