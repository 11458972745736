import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import runtime from './runtime.reducer';
import fragrances from './fragrances.reducer';
import orders from './orders.reducer';
import ingredients from './ingredients.reducer';
import kurt from './kurt.reducer';
import status from './status.reducer';

export default combineReducers({
  fragrances,
  ingredients,
  runtime,
  orders,
  kurt,
  status,
  form: formReducer,
});
