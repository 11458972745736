import {
  ORDER_FETCH_FAIL,
  ORDER_FETCH_REQUEST,
  ORDER_FETCH_SUCCESS,
} from '../constants';

const initialState = {
  loading: false,
  current: null,
  waiting: null,
  state: null,
};

export default function orders(state = initialState, action) {
  switch (action.type) {
    case ORDER_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload.current,
        waiting: action.payload.waiting,
        state: action.payload ? action.payload.state : null,
      };
    case ORDER_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        current: null,
        state: 1,
      };
    default:
      return state;
  }
}
