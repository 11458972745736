const initialState = {
  machineState: -1,
  activeBottles: 0,
  exitBottles: 0,
  exitGates: [],
  errors: [],
  printer: { remaining: 0, total: 0 },
};

export default function kurt(state = initialState, { type, payload }) {
  switch (type) {
    case 'kurt.state.update.machine': {
      return {
        ...state,
        machineState: payload.machineState,
      };
    }
    case 'MACHINE_STATUS': {
      return {
        ...state,
        machineState: payload.machineState,
        activeBottles: payload.activeBottles.length,
        exitBottles: payload.exitBottles.length,
        exitGates: payload.exitGates,
        errors: payload.errors,
        printer: payload.printer,
      };
    }
    default: {
      return state;
    }
  }
}
